export const STATE_OF_RECORD = {
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED'
}

export const COLORS = {
  BASIC_BLUE: '#B4DFF8',
  PURPLE: '#483392',
  WHITE: '#FFF',
  BLACK_80: 'rgba(1, 1, 1, 0.8)',
  BLACK: '#000',
  ORANGE: '#f37029'
}

export const TITLE_SIDEBAR = {
  HOME: 'Trang chủ',
  STUDENT: 'Học viên',
  COURSE: 'Khóa học',
  CERTIFICATION: 'Chứng chỉ',
  CLASS: 'Lớp học',
  REPORT: 'Báo cáo',
  EXPLORE: 'Khám phá',
  CUSTOM_COURSE: 'Danh sách khoá học',
  BUG_REPORT: 'Báo lỗi',
  LECTURE: 'Bài học',
  QUESTION_BANK: 'Ngân hàng câu hỏi',
  CERTIFICATION_TEMPLATES: 'Chứng chỉ khóa học',
  REPORT_CLASS: 'Báo cáo lớp học',
  STUDENT_GRADE_REPORT: 'Báo cáo học viên',
  STATISTIC_TEACHING_HOUR: 'Thống kê giờ dạy',
  WORKFLOW_REPORT: 'Báo cáo quy trình',
  COMPARE_CLASSES: 'So sánh chỉ số lớp học'
}

export const TITLE = {
  MY_ORDERS: 'Đơn hàng của tôi',
  CERTIFICATE: 'Chứng chỉ',
  PERSONAL_INFO: 'Trang cá nhân',
  ADD_STUDENT: 'Thêm học viên',
  IMPORT_CERTIFIED: 'Cấp chứng chỉ',
  ADD_CERTIFICATION_TEMPLATE: 'Thêm mẫu chứng chỉ',
  CREATE_EXAM: 'Tạo kỳ thi',
  CREATE_CLASS: 'Thêm mới lớp học',
  ADD_QUIZ: 'Thêm bài kiểm tra',
  ADD_LECTURE: 'Thêm bài học',
  EDIT_LECTURE: 'Sửa bài học',
  CREATE_COURSE: 'Thêm khóa học',
  ADD_QUESTION: 'Thêm câu hỏi',
  EDIT_QUESTION: 'Sửa câu hỏi',
  IMPORT_QUESTION: 'Thêm ngân hàng câu hỏi'
}

export const LEARNING_SPACE = {
  KY_NANG_SONG: 'kynangsong',
  NAL: 'nal'
}
